import React from "react";
import { graphql } from "gatsby";
import * as _ from "./config/fragments";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  HomeHeader,
  HomeWhySection,
  HomeWhatSection,
  HomeSolutionsSection,
  CTASection,
} from "../components/blocks";

export default function HomePage(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.allSanityHomePage.edges[0].node;

  const services = [
    {
      title: pageData.whatWeDo?.service1?.title?.localized,
      description: pageData.whatWeDo?.service1?.description?.localized,
      iconUrl: pageData.whatWeDo?.service1?.iconImage.asset?.url,
    },
    {
      title: pageData.whatWeDo?.service2?.title?.localized,
      description: pageData.whatWeDo?.service2?.description?.localized,
      iconUrl: pageData.whatWeDo?.service2?.iconImage.asset?.url,
    },
    {
      title: pageData.whatWeDo?.service3?.title?.localized,
      description: pageData.whatWeDo?.service3?.description?.localized,
      iconUrl: pageData.whatWeDo?.service3?.iconImage.asset?.url,
    },
  ];
  const solutionPreviews = pageData.solutionsSection?.solutions.map(item => {
    return item.preview;
  });

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}
      contact={sharedData.contact}>
      <SEO title={pageData.pageTitle?.localized} />
      <HomeHeader
        title={pageData.header?.title?.localized}
        description={pageData.header?.description?.localized}
        coverImageUrl={pageData.header?.coverImage?.asset?.url}
        callToAction={pageData.header?.callToAction}
      />
      <HomeWhySection
        title={pageData.why?.title?.localized}
        description={pageData.why?.description?.localized}
      />
      <HomeWhatSection title={pageData.whatWeDo?.title?.localized} services={services} />
      <HomeSolutionsSection
        title={pageData.solutionsSection?.title?.localized}
        description={pageData.solutionsSection?.description?.localized}
        solutions={solutionPreviews}
      />
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        callToAction={sharedData.callToActionSection?.callToAction}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($locale: String) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...SharedSections
        }
      }
    }
    # Page
    allSanityHomePage {
      edges {
        node {
          pageTitle {
            localized(locale: $locale)
          }
          header {
            title {
              localized(locale: $locale)
            }
            description {
              localized(locale: $locale)
            }
            coverImage {
              ...Image
            }
            callToAction {
              ...PageAction
            }
          }
          why {
            title {
              localized(locale: $locale)
            }
            description {
              localized(locale: $locale)
            }
          }
          whatWeDo {
            title {
              localized(locale: $locale)
            }
            service1 {
              ...IconBulletItem
            }
            service2 {
              ...IconBulletItem
            }
            service3 {
              ...IconBulletItem
            }
          }
          solutionsSection {
            title {
              localized(locale: $locale)
            }
            description {
              localized(locale: $locale)
            }
            solutions {
              preview {
                ...SolutionPreview
              }
            }
          }
        }
      }
    }
  }
`;
